import React from 'react';
import Container from 'elements/Container';
import { Link , withPrefix } from 'gatsby';
import { DOCS_URL } from 'data/urls';
import Button from 'elements/Button';
import { useAuthValue } from 'utils/auth';

import { HeroBase, Platforms } from './style';

export default function LandingPageHero({ children, imgsrc }) {
  const { isLoggedIn } = useAuthValue();

  return isLoggedIn && (
    <Container>
      <HeroBase>
        <div className="left-box">
          {children}
          <div className="btn-group" style={{ marginBottom: '75px' }}>
            <Button
              primary
              as={Link}
              to={DOCS_URL}
            >
              GET STARTED
            </Button>
          </div>
        </div>
        <div className="right-box">
          <img src={imgsrc} alt="" />
        </div>
      </HeroBase>
      <Platforms>
        <div className="logos">
          <img src={withPrefix('images/logos/gojek.png')} alt="gojek-logo" />
          <img src={withPrefix('images/logos/tokopedia.png')} alt="tokopedia-logo" />
          <img src={withPrefix('images/logos/gtf.png')} alt="gopay-logo" />
        </div>
      </Platforms>
    </Container>
  );
}
