import React from 'react';
import Layout from 'layout';
import Hero from 'components/Home/Hero';
import { withPrefix } from 'gatsby';

const IndexPage = ({ location }) => {
  return (
    <Layout noFooterMargin location={location}>
      <Hero imgsrc={withPrefix('/images/landing-page/hero.png')}>
        <h1>
          GoTo Tech Office
          {/* <br /> */}
          {/* data-driven decisions */}
        </h1>
        <h2>
          Platform
        </h2>
        <p style={{ margin: '22px 0 28px 0'}}>
          GoTo&apos;s leadership recognizes that the growing complexity of our technology stack
          has become an unsustainable tax on our productivity, efficiency and our financial bottom line. 
          <br />
          <br />
          The purpose of this Accord is to establish a collaborative effort among the operating companies
          to improve the maturity of our collective technology stack and the efficiency of our engineering
          teams with the ultimate goal of reducing cost and complexity while enabling valuable new capabilities for our users. 
        </p>
      </Hero>
    </Layout>
  );
};

export default IndexPage;
