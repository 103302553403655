import styled from 'styled-components';
import { flexCenter } from 'styles/mixins';
import { headML } from 'styles/typography';
import { alignFlexCenter } from 'styles/utilities';
import rgba from 'polished/lib/color/rgba';

export const HeroBase = styled.div`
  display: flex;
  min-height: 60vh;

  .left-box {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 86px;

    h1 {
      ${headML};
      max-width: 520px;
      margin: 0;
    }

    p {
      margin: 24px 0 56px 0;
      letter-spacing: 0.25px;
      max-width: 450px;
    }

    .btn-group {
      ${alignFlexCenter};
      a {
        font-size: 14px;
        padding: 17px 30px;
        min-width: 180px;
      }
      a + a {
        margin-left: 32px;
      }
    }
  }

  .right-box {
    padding-top: 66px;
    flex: 7;
    ${flexCenter()}
    max-height: 1000px;
    max-width: 1100px;
  }
`;

export const Platforms = styled.div`
  margin-bottom: 180px;
  .logos {
    img {
      height: 25px;
      margin-right: 36px;
      filter: grayscale(1);
      opacity: 0.5;
    }
  }

  .text {
    color: ${props => rgba(props.theme.textMain, 0.4)};
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 16px;
  }
`;
